/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/



import '@quasar/extras/roboto-font/roboto-font.css'

import '@quasar/extras/material-icons/material-icons.css'

import '@quasar/extras/material-icons-round/material-icons-round.css'

import '@quasar/extras/line-awesome/line-awesome.css'

import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'




// We load Quasar stylesheet file
import 'quasar/dist/quasar.sass'




import 'src/css/app.scss'

import 'src/css/vtooltip.css'

import '@quasar/quasar-app-extension-qpdfviewer/src/component/pdfviewer.sass'


import Vue from 'vue'
import createApp from './app.js'




import qboot_Bootaxios from 'boot/axios'

import qboot_BootmyCookies from 'boot/myCookies'

import qboot_Bootappdebug from 'boot/app-debug'

import qboot_Bootveevalidate from 'boot/vee-validate'

import qboot_BootvTooltip from 'boot/vTooltip'

import qboot_BootvMoney from 'boot/vMoney'

import qboot_Bootmoment from 'boot/moment'

import qboot_Bootvuepdf from 'boot/vue-pdf'

import qboot_Bootcannaapiservice from 'boot/canna.api.service'

import qboot_Bootvideo_Player from 'boot/video_Player'

import qboot_Bootcropper from 'boot/cropper'

import qboot_Bootcanna_settings from 'boot/canna_settings'

import qboot_Boot__cannav2_app from 'boot/__cannav2_app'

import qboot_Quasarquasarappextensionqpdfviewersrcbootqpdfviewerjs from '@quasar/quasar-app-extension-qpdfviewer/src/boot/qpdfviewer.js'












const publicPath = `/`


async function start () {
  const { app, store, router } = await createApp()

  
  // prime the store with server-initialized state.
  // the state is determined during SSR and inlined in the page markup.
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__)
  }
  

  
  let hasRedirected = false
  const redirect = url => {
    hasRedirected = true
    const normalized = Object(url) === url
      ? router.resolve(url).route.fullPath
      : url

    window.location.href = normalized
  }

  const urlPath = window.location.href.replace(window.location.origin, '')
  const bootFiles = [qboot_Bootaxios,qboot_BootmyCookies,qboot_Bootappdebug,qboot_Bootveevalidate,qboot_BootvTooltip,qboot_BootvMoney,qboot_Bootmoment,qboot_Bootvuepdf,qboot_Bootcannaapiservice,qboot_Bootvideo_Player,qboot_Bootcropper,qboot_Bootcanna_settings,qboot_Boot__cannav2_app,qboot_Quasarquasarappextensionqpdfviewersrcbootqpdfviewerjs]

  for (let i = 0; hasRedirected === false && i < bootFiles.length; i++) {
    if (typeof bootFiles[i] !== 'function') {
      continue
    }

    try {
      await bootFiles[i]({
        app,
        router,
        store,
        Vue,
        ssrContext: null,
        redirect,
        urlPath,
        publicPath
      })
    }
    catch (err) {
      if (err && err.url) {
        window.location.href = err.url
        return
      }

      console.error('[Quasar] boot error:', err)
      return
    }
  }

  if (hasRedirected === true) {
    return
  }
  

  
    
    const appInstance = new Vue(app)

    // wait until router has resolved all async before hooks
    // and async components...
    router.onReady(() => {
      
      appInstance.$mount('#q-app')
    })
    

  

}

start()
