import Vue from 'vue'

const cannaEstatus = { 
  usuario: {
		DELETED : 0, // 
    INACTIVE : 9, // 
    ACTIVE : 10, // Registro de un nuevo usuario desde frontend/api
    DATOS_PERSONALES : 11, // 
    DATOS_LEGALES : 12, // 
    FOTO : 13, // 
    PUESTOS : 14, // 
    SUELDO : 15, // 
    JORNADA : 16, // 
    DIRECCION : 17, // 
    PAIS : 18, // 
    LICENCIA : 19, // 
    TIENES_AUTO : 20, // 
    MEDIDAS : 21, // 
    ESCOLARIDAD : 22, // 
    CANALES_EXPERIENCIA : 23, // 
    LABORAL_EXPERIENCIA : 24, // dev-sprint 12  (nueva pantalla) 
    CURRICULUM : 25, // ---> 25 
    REDES_SOCIALES : 26, // ---> 26 
    PREVIEW : 27, //	---> 27 
    COMPLETO : 28, //		---> 28 // FRONT (formulario e infoemacion completada al 100%) 
    CONTRATADO : 29, //	---> 29 
    NO_DESEADO : 30 // ---> 30 
  },
  preseleccion: { 
		NO_ASIGNADO_CANDIDATO : 0 , //  Pre-Seleccionado
		ASIGNADO_CANDIDATO : 1 , //  Aprobado
		APROBADO_CANDIDATO : 5 , //  Aprobado
		PENDIENTE_CANDIDATO : 3 , //  Pendiente aprobar
		RECHAZADO_CANDIDATO : 4 , //  No Aprobado
		ASIGNADO_COLABORADOR : 2 , //  Contratado
		APROBADO_COLABORADOR : 6 , //  Contratado
		NO_ASIGNADO_COLABORADOR : 7 , //  Contratado
		VALIDACION_CANDIDATO : 8 , //  Verificación pendiente
		BAJA_INICIA_COLABORADOR : 9 , //  Contratado
		BAJA_APROBADA_COLABORADOR : 10 , //  No aplica
		NO_DISPONIBLE : 11, //  No disponible (sin imagen) 
    RECABAR_INFORMACION : 12 , // Estatus persona != 28  Preseleccion tiene 12  No tiene contrato 
    POSTULADO : 13 // dev-sprint12  // nuevo estatus // dev-sprint 12  (Solicitar Aplicar a vacante)
  }
  // por si se usan en un futuro (+ dev-sprint12)
  // ,
  // aprobacion: {
	// 	APROBACION_PENDIENTE : 0 , 
	// 	APROBACION_APROBADO : 1 , 
	// 	APROBACION_RECHAZADO : 2 , 
	// 	APROBACION_DEFINITIVA_APROBADO : 3 , 
	// 	APROBACION_DEFINITIVA_RECHAZADO : 4,
  //   APROBACION_RECABAR_INFORMACION : 5
  // },
  // contratos: {
  //   NO_CONTRATADO : 0 ,
  //   VERIFICACION_PENDIENTE : 1 ,
  //   CONTRATADO : 2 ,
  //   CONTRATADO_LEIDO : 3 ,
  //   EXPIRADO : 4 ,
  //   TERMINADO : 5 ,
  //   BAJA_EN_PROCESO : 6 ,
  //   BAJA_COMPLETADA : 7 ,
  //   RECHAZAR_RENOVACION : 8 ,
  //   PENDIENTE_RENOVACION : 9 ,
	// 	CONTRATACION_EN_PROCESO : 10 ,
	// 	CONTRATACION_CANCELADA : 11 ,
	// 	BAJA_PENDIENTE : 12 ,
	// 	BAJA_CANCELADA : 13
  // }, 
  // tiposContrato :{ 
  //   TIPO_INDETERMINADO : 0 ,
  //   TIPO_DETERMINADO : 1 ,
  //   TIPO_DETERMINADO_RECURRENTE : 2 ,
  //   TIPO_BAJA : 3 
  // }
}

Vue.prototype.$cannaEstatus = cannaEstatus

const estatusUsuario = cannaEstatus.usuario
const estatusPreseleccion = cannaEstatus.preseleccion
// const estatusAprobacion = cannaEstatus.aprobacion
// const estatusContratos = cannaEstatus.contratos
// const tiposContratos = cannaEstatus.tiposContrato

export {
  cannaEstatus ,
  estatusUsuario,
  estatusPreseleccion,
  // estatusAprobacion, 
  // estatusContratos,
  // tiposContratos,
  // expedientes_carteras // PAra uso del modulo expedientes, carteras y MODAL expediente (normal)
}
