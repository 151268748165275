
const myplatform_Mixin = {
  created: function () {
    this.$cannaDebug('Platform -- Mixin created')
  },
  data () {
    return {
      _platformMixinVer: '1.0.1'
    }
  },
  computed: {
    is_Mobile () {
      return this.$q.platform.is.mobile
    },
    pageSize () {
      return this.$q.screen.name
    },
    pageHeight () {
      return this.$q.screen.height
    },
    pageWidth () { 
      return this.$q.screen.width 
    },
    pageName () {
      return this.$route.meta.pageName
    },
    is_Androidx () { 
      return this.$q.platform.is.android
    }, 
    is_OperaAndMobilSupport () {
      return (this.$q.platform.is.opera && this.$q.platform.is.mobile)  ? true : false 
    }
  },
  methods: {
    doNothing () {
      this.$cannaDebug('-- Empty action')
    }
  }
}

export default myplatform_Mixin
