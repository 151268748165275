
const state = {
  version: '1.0.1',
  parentApp: '' + process.env.PARENTAPP,
  appSessionAlert: false,
  appHasLogin: false,
  appClientIp: '',
  appUserData: {
    access_token: '',
    ipClient: ''
  },
  appVerifyEmail:{ //Opciones para la verificacion de correo 
    action: false,
    action_done: null,
    errors: false,
    response: null ,
    data: null // {key: 'D88bKMwj1625995371', user_id: 127 }
  },
  appResetAccess: { //Opciones para Reset de acceso /limpia     // Solo para control  Temporal 
    action: false,
    action_done: null,
    data: null // {key: 'D88bKMwj1625995371', user_id: 127 }
  },
  appUserProfile: {},
  currentVacanteDetail: null,
  initAppLoading: false,
  helperCatalog: [], // Aqui contiene todos los catalogos de datos (orden DESKTOP-WEB) que se usan e la app (la carga es conforme demanda)
  helperCatalogMobil: [], // Aqui contiene todos los catalogos de datos (orden MOBIL) que se usan e la app (la carga es conforme demanda)
  models: { //Estos son los controles de servicios del API para hacer requests y como se controlaran para realizar las llamadas dentro del sistema 
    // rutas para  -> /index.php/api/v1
    // --------------
    // Access
    _userlogin: ['loginx', ''],
    // --------------
    // Registro
    userReg: ['regx', 'user'],
    passForgot: ['regx','user/password-request'], //${secure}://${server}/index.php/api/rum1/user/password-request?notifier=email&identifier=cmendez21@gmail.com
    passRenew: ['regx',['user','password-reset']], // ${secure}://${server}/index.php/api/rum1/user/104/password-reset?key=VIxZrsmA1624570078&password=Homer_33
    userVerifica: ['regx',['user','verify']], 
      // ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=email   
      // ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=phone
    // API V2 -------
		recover_Email: ['rcpass', 'validar-email'], // ${secure}://${server}/index.php/api/v2/validar-email?email=cmendez21@gmail.com
		recover_Sms: ['rcpass', 'validar-telefono'], //${secure}://${server}/index.php/api/v2/validar-telefono?phone=+525572215548
		recover_clavesOf: ['rcpass', 'validar-legales'], //${secure}://${server}/index.php/api/v2/validar-legales
		//--------------
    preCelular: ['register','pre-cel'], //api/v2/pre-cel?numero=
		preRegistro: ['register','pre-registro'], // ${secure}://${server}/index.php/api/v2/pre-registro?curp=SAGC930520HDFLRS01&rfc=SAGC-930520-FQ8&nss=82-11-93-3548-5
    helperCategorias: ['v2', 'categoria'],
    helperCatCatalog: ['v2', ['categoria', 'catalogo']],
    // Utilerias 
    telefonoCheck: [ 'v2', 'telefono'], // ${secure}://${server}/index.php/api/v2/telefono?numero=+525572215548 
    // --------------
    // Codigos postales
    postalCode:['v2','codigo-postal'],  // ${secure}://${server}/index.php/api/v2/codigo-postal?d_asenta=Aragon
    // --------------
    userProfile: ['v2', 'profile'],
    profile: ['v2', 'profile'],
    profileDireccion: ['v2','profile/direccion'], // ${secure}://${server}/index.php/api/v2/profile/direccion
    profilePersonales: ['v2', 'profile/datos-personales'],
    profileLegales: ['v2', 'profile/datos-legales'],
    profileChgPassx: ['v2', 'profile/cambiar-password'],
    profileFiltro: [ 'v2', 'profile/filtro'], // ${secure}://${server}/index.php/api/v2/profile/filtro?categoria_id=6,7&per-page=all&expand=catalogo
    profile_Docs:['v2','profile/documento'], // ${secure}://${server}/index.php/api/v2/profile/documento?per-page=all&expand=catalogo&sort=-id
    profile_Videos:['v2', 'profile/video'], //${secure}://${server}/index.php/api/v2/profile/video?per-page=all&expand=catalogo&sort=-id
    profile_Laboral:['v2', 'profile/experiencia-laboral'], // ${secure}://${server}/index.php/api/v2/profile/experiencia-laboral?per-page=all&sort=id
    profile_Vacantes: ['v2','vacantes'], // ${secure}://${server}/index.php/api/v2/vacantes?per-page=20&expand=compensacionesPago,jornada,horario
    profile_Vacantes_Postular:['v2', 'plan-perfil-laboral-seleccion'], //${secure}://${server}/index.php/api/v2/plan-perfil-laboral-seleccion
    userSolicitud: ['v2','profile/solicitud'], // ${secure}://${server}/index.php/api/v2/profile/solicitud/2?per-page=all&sort=-created_at
    userPdfSolicitud: ['v2','profile/descarga-solicitud'] //${secure}://${server}/index.php/api/v2/profile/descarga-solicitud
  },
  catalogosV2: {},
  catalogosV1: {
    tester: [{ id: 1, name: 'data 1' }, { id: 2, name: 'data 2' }, { id: 3, name: 'data 3' }],
  },
  loginData:{
    logIn: false, user: null, token: null
  }
}

const getters = {
  appUserIp: function (state) {
    return state.appClientIp
  },
  appData: function (state) {
    return state.appUserData
  },
  appProfileData: function (state) {
    return state.appUserProfile
  },
  appLoginData: function (state) { 
    return state.loginData
  },
  modelRequest: (state) => (id) => {
    if (state.models[id] !== undefined) {
      return state.models[id]
    } else {
      return null
    }
  },
  helperCatalogoMobil: (state) => (idx) => {
    if (state.helperCatalogMobil.length <= 0) {
      return []
    } else { 
      var indexCat = state.helperCatalogMobil.findIndex(item => item.id === idx)
      if (indexCat < 0) {
        return []
      } else {
        return state.helperCatalogMobil[indexCat]
      }
    }
  },
  helperCatalogo: (state) => (idx) => {
    if (state.helperCatalog.length <= 0) {
      return []
    } else { 
      var indexCat = state.helperCatalog.findIndex(item => item.id === idx)
      if (indexCat < 0) {
        return []
      } else {
        return state.helperCatalog[indexCat]
      }
    }
  }
}

const mutations = {
  appLogout: (state, payload) => { 
    state.appSessionAlert = payload.exit
  },
  setInitAppBaseUrl: (state, payload) => {
    state.baseAppUrl = payload.url
  },
  setBaseAppClientIp: (state, payload) => {
    state.appClientIp = payload.ipUse
  },
  setMainState: (state, o) => {
    // Example: this.$store.commit('myapp/setMainState', myObject)
    for (const u in o) {
      state[u] = o[u]
    }
  },
  setAppUserProfileData: (state, o) => {
    // Example: this.$store.commit('myapp/setAppUserProfileData', myObject)
    for (const u in o) {
      state.appUserProfile[u] = o[u]
    }
  },
  setAppUserData: (state, o) => {
    // Example: this.$store.commit('myapp/setAppUserData', myObject)
    for (const u in o) {
      state.appUserData[u] = o[u]
    }
  },
  setAppVerifyEmail: (state, o) => {
    // Example: this.$store.commit('myapp/setAppVerifyEmail', myObject)
    // Example 2 : this.$store.commit('myapp/setAppVerifyEmail', { errors: true, response:'error' }) // Solo 2 props 
    for (const u in o) {
      state.appVerifyEmail[u] = o[u]
    }
  },
  setAppResetAccess: (state, o) => {
    // Example: this.$store.commit('myapp/setAppResetAccess', myObject)
    // Example 2 : this.$store.commit('myapp/setAppResetAccess', { errors: true, response:'error' }) // Solo 2 props 
    for (const u in o) {
      state.appResetAccess[u] = o[u]
    }
  },
  setProfile: (state, o) => {
    for (const u in o) {
      state.profile[u] = o[u]
    }
  },
  setloginData: (state, o) => {
    for (const u in o) {
      state.loginData[u] = o[u]
    }
  },
  setHelperCatData: (state, payload) => {
    // Example: this.$store.commit('myapp/setHelperCatData', {catId: 6, modo: 1, data: [....] })
    var idx = payload.catId 
    var indexCat = state.helperCatalog.findIndex(item => item.id === idx) // Siempre tiene que existir 
    if (indexCat >= 0) {
      state.helperCatalog[indexCat].dataCatalog = JSON.parse(JSON.stringify(payload.data))
      if (payload.hasOwnProperty('datamovil')){ // para pruebas 
        state.helperCatalog[indexCat].dataCatalogMovil = JSON.parse(JSON.stringify(payload.datamovil))
      }
    }
  },
  setHelperCatInfo: (state, payload) => {
    // Example: this.$store.commit('myapp/setHelperCatInfo', {catId: 6, modo: 1, data: [....] })
    var idx = payload.catId 
    var indexCat = state.helperCatalog.findIndex(item => item.id === idx) // Siempre tiene que existir 
    if (indexCat >= 0) {
      for (const u in payload.data) {
        state.helperCatalog[indexCat][u] = payload.data[u]
      }
    }
  },
  // setHelperCatDataSORT: (state, payload) => { // para pruebas 
  //   // Example: this.$store.commit('myapp/setHelperCatDataSORT', {catId: 6, modo: 1, data: [....] })
  //   var idx = payload.catId 
  //   var campox = payload.campoUse 
  //   var indexCat = state.helperCatalog.findIndex(item => item.id === idx) // Siempre tiene que existir 
  //   if (indexCat >= 0 && campox!==undefined && campox!==null && campox!=='') {
  //     state.helperCatalog[indexCat].dataCatalog.sort(function (a, b) {
  //       const Ao = a[campox]
  //       const Bo = b[campox]
  //       let comparison = 0
  //       comparison = (Ao > Bo) ? 1 : (Ao < Bo) ? -1 : 0
  //       return comparison
  //     })
  //   }
  // },
  setHelperCatMobilData: (state, payload) => {
    // Example: this.$store.commit('myapp/setHelperCatMobilData', {catId: 6, modo: 1, data: [....] })
    var idx = payload.catId 
    var indexCat = state.helperCatalogMobil.findIndex(item => item.id === idx) // Siempre tiene que existir 
    if (indexCat >= 0) {
      state.helperCatalogMobil[indexCat].dataCatalog = JSON.parse(JSON.stringify(payload.usedata))
    }
  },

  setHelperCatMobilInfo: (state, payload) => {
    // Example: this.$store.commit('myapp/setHelperCatMobilInfo', {catId: 6, modo: 1, data: [....] })
    var idx = payload.catId 
    var indexCat = state.helperCatalogMobil.findIndex(item => item.id === idx) // Siempre tiene que existir 
    if (indexCat >= 0) {
      for (const u in payload.usedata) {
        state.helperCatalogMobil[indexCat][u] = payload.usedata[u]
      }
    }
  },

  // ,
  // setDemoData: (state, payload) => {
  //   state.demoData = payload.value
  // }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
