import { mapGetters } from 'vuex'

const helperBase_Mixin = {
  created: function () {
    this.$cannaDebug('Helper -- Mixin created')
  },
  data () {
    return {
      zz_helperMixinVer: '1.0.1'
    }
  },
  computed: {
    ...mapGetters({
      getModelRequest: 'myapp/modelRequest',
      getAppData: 'myapp/appData',
      getProfileData: 'myapp/appProfileData',
      getLoginData: 'myapp/appLoginData',
      getHelperCat: 'myapp/helperCatalogo', // Catalogos plain y para desktop 
      getHelperCatMobil: 'myapp/helperCatalogoMobil' // Catalogos para mobiles
    }),
    zstatUser(){
      return this.$cannaEstatus.usuario
    },
    currProfile_status (){
      return parseInt(this.getProfileData.status)
    }
  },
  methods: {
    async hpb_refreshProfileInfo (){
      var test = 11 
      var mpModel = this.getModelRequest('userProfile')
      var reqRes = { profile: [] }
      var reqErr = { profile: 0 }
      var myProfileConfig = this.createAxiosConfig ('get', mpModel, { test: 11, expand:'experienciaLaboral,userPerfilLaboralFiltro' }, true, null)
      // ------------------
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profile, myProfileConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.profile[reqRes.profile.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.profile += 1
        }
      })
      if (reqErr.profile > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        console.log('---- ZZZZ -- Error al actualizar profile status ---')
        console.log(reqRes.action[0])
        // this.$cannaDebug('-- loginscreen -- Error al cargar el profile -- ')
        // this.loading_Process = false
        return false
      }
      var profileData = reqRes.profile[0].data.data
      this.$store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await this._waitRequestProc(200)
      // ------------------
      return true
    },
    async hpb_updateProfileStatus(newStatus){
      var test = 11 
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var mpxModel = this.getModelRequest('userProfile')
      // ----------------------
      var myProfileEditConfig = this.createAxiosConfig ('patch', mpxModel, { useData:false, data: { status: newStatus} } , true, null)
      await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.action, myProfileEditConfig).then((resx) => {
        // console.log ('Action -- Carga de Clientes -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 44
        console.log('---- ZZZZ -- Error al actualizar profile status ---')
        console.log(reqRes.action[0])
        // this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        return false
      }
      this.$store.commit('myapp/setAppUserProfileData', {status: newStatus} )
      await this._waitRequestProc(100)
      return true
      // { id: 1, name: 'dashboard_pi_foto', pctVal: 2, estatusMin: 12, estatusReal: 13 },
      // { id: 2, name: 'dashboard_pi_puestos', pctVal: 4, estatusMin: 13, estatusReal: 14 },
      // { id: 3, name: 'dashboard_pi_sueldos', pctVal: 8, estatusMin: 14, estatusReal: 15 },
      // { id: 4, name: 'dashboard_pi_horario', pctVal: 17, estatusMin: 15, estatusReal: 16 },
      // { id: 5, name: 'dashboard_pi_direccion', pctVal: 22, estatusMin: 16, estatusReal: 17 },
      // { id: 6, name: 'dashboard_pi_pais', pctVal: 26, estatusMin: 17, estatusReal: 18 },
      // { id: 7, name: 'dashboard_pi_licencia', pctVal: 35, estatusMin: 18, estatusReal: 19 },
      // { id: 8, name: 'dashboard_pi_tieneauto', pctVal: 39, estatusMin: 19, estatusReal: 20 },
      // { id: 9, name: 'dashboard_pi_medidas', pctVal: 48, estatusMin: 20, estatusReal: 21 },
      // { id: 10, name: 'dashboard_pi_escuela', pctVal: 65, estatusMin: 21, estatusReal: 22 },
      // { id: 11, name: 'dashboard_pi_canales', pctVal: 78, estatusMin: 22, estatusReal: 23 },
      // { id: 12, name: 'dashboard_pi_curriculum', pctVal: 83, estatusMin: 23, estatusReal: 24 },
      // { id: 13, name: 'dashboard_pi_sociales', pctVal: 87, estatusMin: 24, estatusReal: 25 },
      // { id: 14, name: 'dashboard_pi_review', pctVal: 96, estatusMin: 25, estatusReal: 26 }
    }
  }
}

export default helperBase_Mixin
