

import { Cookies } from 'quasar'
import CryptoJS from "crypto-js";
import axios from 'axios'

import { requestPack } from './axios.js'
import { estatusUsuario } from './canna_settings.js'

const cookieMasterKey = process.env.CCPX 
const cookieMainName  = 'CannaV2Public'
const cookieMasterOptions = { expires: '1d', path: '/', httpOnly: false, sameSite:'Lax', secure: false } // , appName: 'CannaV2Public'

const cannack_getCookieData = function ( AltCookie ) {
  var cookies = null
  if (AltCookie == undefined || AltCookie===null || AltCookie === ''){ 
    cookies = Cookies
  } else { 
    cookies = AltCookie
  }
  try { 
    var cannaExist = cookies.has(cookieMainName)
    if (cannaExist===true) {
      var strCookie = cookies.get('CannaV2Public')
      var bytes = CryptoJS.AES.decrypt(strCookie, cookieMasterKey);
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      var myDataJS = JSON.parse(originalText)
      return myDataJS
    } else {
      return null
    }
  } catch (ex) {
    console.log ('error en cannack_setCookieData')
    // console.log(ex) 
    return null 
  }
}

const cannack_destroyCookie = function ( cookieLib, cookieName ) { // Mejorado para destuir cualquier cookie

  var myCookieName = '' + cookieName
  var cannaExist = cookieLib.has(myCookieName)
  if (cannaExist===true) {
    cookieLib.remove(myCookieName, cookieMasterOptions)
    cookieLib.remove(myCookieName)
    return true
  } else {
    return false
  }

}

const cannack_createCookie = function ( cookieLib , cookieName, value, protect ){ 
  // cookieLib : la instancia de la libreria q manipula el proceso 
  // cookieName : el nombre de la cookie 
  // value :  debe ser un objeto
  // protect: Boolean  True / False, null, undefined
  var myVal = null
  var myCookieName = '' + cookieName
  try {
    if (protect === true) {
      var myDataStrWW = JSON.stringify(value)
      var cipherTextWW = CryptoJS.AES.encrypt( myDataStrWW, process.env.CCPX).toString();
      myVal = cipherTextWW
    } else { 
      myVal = JSON.stringify(value) 
    }
    cookieLib.set(myCookieName, myVal, cookieMasterOptions )
    return true
  } catch (ex) {
    console.log('Error ck0001 - al crear la cookie...!')
    return false 
  }
}

const cannack_setCookieData = function ( cookieLib , dataObject ) { // Para establecer info en cookie llamada 'CannaV2Public'
  var cannaExist = cookieLib.has(cookieMainName)
  if (cannaExist ===true) {
    try { 
      // var strCookie = cookieLib.get('CannaV2Public')
      // var bytes = CryptoJS.AES.decrypt(strCookie, cookieMasterKey);
      // var originalText = bytes.toString(CryptoJS.enc.Utf8);
      // var myDataJS = JSON.parse(originalText)
      var myKeys = Object.keys( dataObject )
      if (myKeys.length>0){ 
        var myDataStr = JSON.stringify(dataObject)
        var cipherText = CryptoJS.AES.encrypt(myDataStr, cookieMasterKey).toString();
        cookieLib.set(cookieMainName, cipherText, cookieMasterOptions )
        return true
      } else{
        return false 
      }
    } catch (ex) {
      console.log ('error en cannack _ set Cookie Data')
      console.log(ex)
      return false 
    }
  } else {
    return false
  }
}

const cannack_setCookieDataInfo = function ( cookieLib , cookieName, dataObject ) { // Para establecer info en cookies que no sea 'CannaV2Public'
  var myCookieName = '' + cookieName
  var cannaExist = cookieLib.has(myCookieName)
  if (cannaExist ===true) {
    try { 
      var myKeys = Object.keys( dataObject ) 
      if (myKeys.length>0){ 
        var myDataStr = JSON.stringify(dataObject)
        var cipherText = CryptoJS.AES.encrypt(myDataStr, cookieMasterKey).toString();
        cookieLib.set(myCookieName, cipherText, cookieMasterOptions )
        return true
      } else{
        return false 
      }
    } catch (ex) {
      console.log ('error en cannack _ set Cookie Data')
      console.log(ex)
      return false 
    }
  } else {
    return false
  }
}

const _waitRequestProcIntern = async function (ms) { // Timer para Esperar de manera asyncrona entre requests 
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

const canna_cookieMethods = {
  cannack_createCookie, // para crear la cookie que se especifique
  cannack_getCookieData, // Para obtneer datos de la cookie que se especifique
  cannack_setCookieData, // Para establecer datos en la cookie principal 
  cannack_setCookieDataInfo, // Para establecer datos en la cookie del nombre que se proporcione 
  cannack_destroyCookie // Para destruir la cookie q se especifique 
}

export {
  cookieMasterOptions,
  canna_cookieMethods
}

// app.use(session({
//   cookieName: "cannaV2AppStructure",
//   secret: "zapazhazhopakz+oazkhsfizhuhfdzzhpuohzhfzdf", //secret: "sapasdasdopaks+oaskdsfisdudfdssdpuohsdfsdf",
//   duration: 24*60*60*1000,       	//Milliseconds, 1 hour will kill cookie
//   activeDuration: 15*60*1000,  		//Milliseconds, 5 minutes of inactivity will kill cookie
//   cookie: {
//     path:'/',
//     httpOnly: false, // when true, cookie is not accessible from javascript
//     secure: false, // when true, cookie will only be sent over SSL. use key 'secureProxy' instead if you handle SSL not in your node process
//     sameSite:"lax",
//   }
// }));

// rutina principal
const cannack_validRoute = function ( haslogin, usePath ) { 
  //Revisamos si la ruta es valida o no conforme si tiene login o no
}

const cannack_valirCambioAccesoParams = function ( path ) {
  // Revisamos q la ruta tenga los parametros validos 
  try {
    var mypath = path 
    var partA = mypath.split("?") 
    if (partA.length !== 2 ){
      return false 
    } else { 
      var partB = partA[1].split("&") 
      if (partB.length!==2){
        return false 
      } else {
        var myAx = partB[0].split["="]
        var myBx = partB[1].split["="]
        if (myAx[0] === 'key' && myBx[0] == 'user_id'){ 
          var testA = (/[A-Za-z0-9_-]{8}/).test(myAx[1])
          var testB = (/\d{10}/).test(myAx[1])
          var testC = (/[0-9]*/).test(myBx[1])
          return (testA && testB && testC) 
        } else {
          return false 
        }
      }
    }
  } catch (ex) { 
    return false 
  }
}

export default async ({ app, router, store, Vue, ssrContext, urlPath, publicPath, redirect }) => {
  // something to do
  var demo = 'prueba de --- ' + process.env.APPTester 
  var test = 11
  // console.log(' -- ' + demo)
  // console.log('--------------- urlPath -- ' + urlPath )
  // console.log('--------------- publicPath -- ' + publicPath )
  // console.log('---------------')
  const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies // otherwise we're on client

  // ------------------------
  // Obtenemos la IP del cliente/visitante 
  // ------------------------
  const { data: { ip } } = await axios.get("https://www.cloudflare.com/cdn-cgi/trace", {
    responseType: "text",
    transformResponse: data =>
      Object.fromEntries(data.trim().split("\n").map(line => line.split("=")))
  })
  store.commit('myapp/setAppUserData', { ipClient: ip })
  store.commit('myapp/setMainState', { appClientIp: ip }) //setloginData

  const loginDataBase = { logIn: false, user: null, token: null } // Objeto Basico de estado de la cookie
  const verifyEmailData_clean =  { //Opciones para la verificacion de correo /limpia 
    action: false,
    action_done: null,
    errors: false,
    response: null ,
    data: null // {key: 'D88bKMwj1625995371', user_id: 127 }
  }
  const resetPassData_clean = { //Opciones para Reset de acceso /limpia 
    action: false,
    action_done: null,
    data: null // {key: 'D88bKMwj1625995371', user_id: 127 }
  }
  // ----------------
  // verificamos si viene alguna llave (query params) en la ruta 
  // y verificar a que ruta corresponde
  // ----------------
  var plainRutaNoQuery = '' // Ruta sin parametros query

  var qxPlainPath = '' //Params para validar correo
  var qxparams = '' //Params para validar correo
  var qxvals = [] //Params para validar correo
  var qxConf = {} //Params para validar correo

  var qrp_Params = '' //params para cambio de Acceso 
  var qrp_vals = [] //params para cambio de Acceso 
  var qrp_Conf = {} //params para cambio de Acceso 

  var qpz_hasEmailValidate = false
  var qpz_hasRestorePass = false



  // Si hay parametros (revisar si aplican a EMAIL o restablecer contraseña)
  if ( urlPath.startsWith('/restaurar-acceso') === true ) { // Para reestablecer contraseña
    qpz_hasEmailValidate = false 
    var urlxUSe = decodeURIComponent(urlPath)
    //if  (urlPath.indexOf('?')>=0 && urlPath.indexOf('key=')>=0 && urlPath.indexOf('user_id=')>=0){
    if  (urlxUSe.indexOf('?')>=0 && urlxUSe.indexOf('key=')>=0 && urlxUSe.indexOf('user_id=')>=0){
      var datosQRPx =  urlxUSe.split('?') // urlPath.split('?') 
      plainRutaNoQuery = datosQRPx[0]
      qrp_Params = '?' + datosQRPx[datosQRPx.length -1]
      qrp_vals = qrp_Params.replace(/\?/,'').split('&')
      qrp_vals.forEach(item => { 
          var pairs = item.split(/\=/)
          qrp_Conf[pairs[0]] = pairs[1]
      })
      // 2da verificacion q las llaves sean key y user_id
      qpz_hasRestorePass = (qrp_Conf.hasOwnProperty('key') && qrp_Conf.hasOwnProperty('user_id')) ? true : false 
    } else {
      qpz_hasRestorePass = false 
      qrp_Params = null
    }
  } else if ( urlPath.startsWith('/restaurar-acceso') === false ) {  // Lo q no sea para restablecer contraseña  es para restablecer email 

    // Para Validar email lo q no sea con ruta de rest acceso // http://candidato/#/verificar?key=WKveEr5E1625082467&user_id=104
    qpz_hasRestorePass = false 
    var urlxUSe = decodeURIComponent(urlPath)
    //if  (urlPath.indexOf('?')>=0 && urlPath.indexOf('key=')>=0 && urlPath.indexOf('user_id=')>=0){
    if  (urlxUSe.indexOf('?')>=0 && urlxUSe.indexOf('key=')>=0 && urlxUSe.indexOf('user_id=')>=0){

      var datosQxp = urlxUSe.split('?') // urlPath.split('?') 
      qxPlainPath =  datosQxp[0]
      plainRutaNoQuery = datosQxp[0]
      qxparams = '?' + datosQxp[datosQxp.length -1]
      qxvals = qxparams.replace(/\?/,'').split('&')
      qxvals.forEach(item => { 
          var pairs = item.split(/\=/)
          qxConf[pairs[0]] = pairs[1]
      })
      // 2da verificacion q las llaves sean key y user_id
      qpz_hasEmailValidate = (qxConf.hasOwnProperty('key') && qxConf.hasOwnProperty('user_id')) ? true : false 

    } else {
      qpz_hasEmailValidate = false
    }

  } 
  
  var rutasNeedLogin = [ '/dashboard', '/settings' ]
  var rutasMayHaveLogin = [ '/nuevacuenta' ]
  var cannaExist = cookies.has('CannaV2Public')
  var strCookie = null
  var appHas_Login = false
  var myData_CKX = null 

  // Revisar si tiene valores la cookie 
  if (cannaExist === true ) {
    strCookie = cookies.get('CannaV2Public')
    if (strCookie==='' || strCookie===undefined || strCookie===null) { 
      strCookie = null
    }
  }
  // Si existe valor en la cooki revisar estado de LOGIN
  if (strCookie!==null){
    //console.log('CannaV2Public Cookie has valor ------------')
    var bytes = CryptoJS.AES.decrypt(strCookie, process.env.CCPX);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    //console.log(originalText);
    // console.log('CannaV2Public Cookie valor as JSON ------------')
    myData_CKX = JSON.parse(originalText)
    if (myData_CKX.hasOwnProperty('logIn')) {
      appHas_Login = (myData_CKX.logIn===true && myData_CKX.user!==null && myData_CKX.token!==null) ? true : false 
    }
  }

  if (cannaExist === false || strCookie ===null || appHas_Login===false ) {
    // Si no existe la cookie (no tiene login)
    // Puede estar en 3 casos (pagina inicial/login, pagina de Restablecer contraseña, pagina de nueva cuenta)

    if (cannaExist === false ){ 
      cannack_createCookie ( cookies , 'CannaV2Public' , loginDataBase, true )
    } else {
      cannack_setCookieData (cookies, loginDataBase)
    }
    if ( urlPath.startsWith('/dashboard') || urlPath.startsWith('/settings')){ //Necesitan login no podemos dejarlos pasar 

      redirect({ path: '/'})
      // console.log(' --  final 0001 --')
      return

    } else {

      if ( urlPath.startsWith('/restaurar-acceso') === true ) { //Checar los params 

        //Checar params para restaurar Acceso 
        if (qpz_hasRestorePass === true){ 

          if (qrp_Params!=='' &&  qrp_vals.length==2 && plainRutaNoQuery !== '') {
            //Armamos la cookie para restablecer contraseña
            var myDataReset = {
              action: true,
              action_done: false,
              data: {key: qrp_Conf.key, user_id: qrp_Conf.user_id }
            }
            var testerRPW_A = cannack_createCookie ( cookies, 'CannaV2PublicResetVz', myDataReset, false)
            //console.log('CannaV2Public -- Reset Acceso -- 000 --- ' + testerRPW_A)
            //console.log(' --  final 0003 --')
            redirect({ path: plainRutaNoQuery })
            return

          } else { //NO deberia de pasar 

            // Si pasa se destruye la cookie 
            //console.log('CannaV2Public -- Reset Acceso Error --  final 0001 --')
            var testDCRP = cannack_destroyCookie ( cookies , 'CannaV2PublicResetVz' )
            redirect({ path: '/'})
            return

          }

        } else {

          // Verificamos si esta la cookie para reset de acceso
          var cannaExistPassResVer = cookies.has('CannaV2PublicResetVz')
          if (cannaExistPassResVer===true){ 
            //Obtenemos la Cookie y verificamos si no se ha realizado el proceso 
            var dataRPVer = cookies.get('CannaV2PublicResetVz')
            if (dataRPVer instanceof Object && dataRPVer.constructor === Object) { 
              if (dataRPVer.action === true && dataRPVer.action_done === false) {
                store.commit('myapp/setMainState',{ appResetAccess: dataRPVer })
              }
              //console.log(' --  final 0006.1 --')
              // Continuamos normal... 
            } else { 
              // No se hace nada... ya que no procede la accion
              store.commit('myapp/setMainState',{ appResetAccess: resetPassData_clean })
              //console.log(' --  final 0006.2 --')
            }
          } else {
            // Continuamos normal... 
            store.commit('myapp/setMainState',{ appResetAccess: resetPassData_clean })
            //console.log(' --  final 0006.3 --')
          }

        }

      } else { // Checar params para validar email 

        //console.log('CannaV2Public -- Ruta general --') 
        if (qpz_hasEmailValidate === true) {

          if (qxparams!=='' &&  qxvals.length==2 && qxPlainPath !== '') {
            //Armamos la cookie para Validar Email 
            var myDataEmail = {
              action: true,
              action_done: false,
              errors: false,
              response: null ,
              data: {key: qxConf.key, user_id: qxConf.user_id }
            }
            var testerEMV_A = cannack_createCookie ( cookies, 'CannaV2PublicEmailVx', myDataEmail, false)
            //console.log('CannaV2Public -- Verificacion EMail -- 000 --- ' + testerEMV_A)
            //console.log(' --  final 0003 --')
            redirect({ path: plainRutaNoQuery })
            return
          }

        } else {

          // Verificamos si esta la cookie para validar email 
          var cannaExistEmailVer = cookies.has('CannaV2PublicEmailVx')
          if (cannaExistEmailVer===true){ 
            //Obtenemos la Cookie y verificamos si no se ha realizado el proceso 
            var dataEmailVer = cookies.get('CannaV2PublicEmailVx')
            if (dataEmailVer instanceof Object && dataEmailVer.constructor === Object) { 
              if (dataEmailVer.action === true && dataEmailVer.action_done === false) {
                store.commit('myapp/setMainState',{ appVerifyEmail: dataEmailVer })
              }
              //console.log(' --  final 0003.1 --')
              // Continuamos normal... 
            } else { 
              // No se hace nada... ya que no procede la accion
              store.commit('myapp/setMainState',{ appVerifyEmail: verifyEmailData_clean })
              //console.log(' --  final 0003.2 --')
            }
          } else {
            // Continuamos normal... 
            store.commit('myapp/setMainState',{ appVerifyEmail: verifyEmailData_clean })
            //console.log(' --  final 0003.3 --')
          }

        }

      }

    }

  } else {
    // SI Existe la cookie..! 
    console.log('CannaV2Public Cookie has session ------------')

    store.commit('myapp/setMainState', { loginData: myData_CKX })
    store.commit('myapp/setAppUserData', { access_token: myData_CKX.token })

    var useToken = myData_CKX.token

    // Obtenemos los datos del profile de la persona logeada 
    var mcModel = store.getters["myapp/modelRequest"]('profile')
    var tempMyAppData = store.getters["myapp/appData"]
    //------------------
    var url = process.env.APIV2 //+ '?accessToken=' + useToken
    var myConfig =  requestPack.createAxiosConfig('get', mcModel, {expand:'experienciaLaboral,userPerfilLaboralFiltro'}, true, {
      myAppData: tempMyAppData,
      baseUrl: process.env.APIV2, // + '?accessToken=' + useToken , 
      access_token: useToken
    })

    test = 22

    var reqRes = { action: [] }
    var reqErr = { action: 0 }
    await requestPack.doRequestByPromise(Vue.prototype.$axios, 'profilepersonales', reqRes.action, myConfig).then((resx) => {
      // console.log ('Action -- Carga de Clientes -- GET ejecutado')
      var tmpresponseData = reqRes.action[reqRes.action.length - 1]
      if (tmpresponseData.type !== 'success') {
        reqErr.action += 1
      }
    })

    if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
      
      // Limpiamos la cookie sin login y redireccionamos a root de app 
      cannack_setCookieData (cookies, loginDataBase)
      redirect({ path: '/'})
      //console.log(' --  final 0002 --')
      return

    } else {

      test = 44
      // Checar params para validar email

      // console.log('CannaV2Public -- Ruta Privada --') 
      if (qpz_hasEmailValidate === true) {

        if (qxparams !=='' &&  qxvals.length==2 && qxPlainPath !== '') {
          //Armamos la cookie para Validar Email 
          var myDataEmailP = {
            action: true,
            action_done: false,
            errors: false,
            response: null ,
            data: {key: qxConf.key, user_id: qxConf.user_id }
          }
          var testerEMV_B = cannack_createCookie ( cookies, 'CannaV2PublicEmailVx', myDataEmailP, false)
          //console.log('CannaV2Public -- Verificacion EMail -- 000 --- ' + testerEMV_B)
          //console.log(' --  final 0005 --')
          redirect({ path: plainRutaNoQuery })
          return
        }

      } else {

        // Verificamos si esta la cookie para validar email 
        var cannaExistEmailVerB = cookies.has('CannaV2PublicEmailVx')
        if (cannaExistEmailVerB===true){ 
          //Obtenemos la Cookie y verificamos si no se ha realizado el proceso 
          var dataEmailVerX = cookies.get('CannaV2PublicEmailVx')
          if (dataEmailVerX instanceof Object && dataEmailVerX.constructor === Object) { 
            if (dataEmailVerX.action === true && dataEmailVerX.action_done === false) {
              store.commit('myapp/setMainState',{ appVerifyEmail: dataEmailVerX })
            }
            //console.log(' --  final 0005.1 --')
            // Continuamos normal... 
          } else { 
            // No se hace nada... ya que no procede la accion
            store.commit('myapp/setMainState',{ appVerifyEmail: verifyEmailData_clean })
            //console.log(' --  final 0005.2 --')
          }
        } else {
          // Continuamos normal... 
          store.commit('myapp/setMainState',{ appVerifyEmail: verifyEmailData_clean })
          //console.log(' --  final 0005.3 --')
        }

      }
      //Cargamos los datos de profile de la persona y redireccionamos a la ruta q corresponda 
      /**
       * Posibles rutas: 
       *  -- /dashboard
       *  -- /settings
       *  -- /nuevacuenta
       */
      var profileData = reqRes.action[0].data.data
      store.commit('myapp/setMainState',{ appUserProfile: profileData })
      await requestPack._waitRequestProc(100)

      // if (  
      //   profileData.status>= estatusUsuario.DATOS_LEGALES  ||  
      //   profileData.status>= estatusUsuario.ACTIVE
      // ){   // estatusUsuario.DATOS_LEGALES = 12
        if (urlPath.startsWith('/pdf-viewer')===true || urlPath.startsWith('/verificar')===true) {
          // Continua al Path de /pdf-viewer o al path de verifica...!        
        } else if (urlPath.startsWith('/dashboard/solicitudes')===true) {
          if (profileData.trab_id===undefined || profileData.trab_id===null || profileData.trab_id===''){
            redirect({ path: '/dashboard' })
            return
          }
        } else {
          if (urlPath.startsWith('/dashboard') === false){
            if (urlPath.startsWith('/settings') ===false){
              redirect({ path: '/dashboard' })
              return
            } else { 
              // Continua al Path de /settings que sea necesario 
            }
          } else {
            // Continua al Path de /dashboard que sea necesario 
          }
        }
      // } else {
      //   if (urlPath.startsWith('/nuevacuenta') ===false){
      //     redirect({ path: '/nuevacuenta' })
      //     return
      //   }
      // }
    }

  }
  // console.log(' final de carga ---------------')
}
