<template>
  <div id="q-app">

    <template v-if="cannaReadyApp===true && cannaInitReady===true">
      <router-view />
    </template>
    
    <q-dialog v-model="alertSession" persistent transition-show="scale" transition-hide="scale"
      :content-class="'cannalogOutDialog'"
    >
      <q-card class="bg-logout text-white noselect" style="width: 400px">
        <q-card-section>
          <div class="text-h6">Atención</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          El tiempo de tu sesión en Canna ha terminado <br>
          Por favor, para poder continuar inicia sesión nuevamente.
        </q-card-section>

        <q-card-actions align="right" class="bg-white text-logout">
          <q-btn flat label="OK" @click="sendAppOut" />
        </q-card-actions>
      </q-card>
    </q-dialog>

  </div>
</template>
<script>
import { requestPack } from './boot/axios' // APP code 
import { canna_cookieMethods } from './boot/__cannav2_app'
import myplatform_Mixin from './mixins/platformMix.js'
import helperBase_Mixin from './mixins/helperBase.js'

export default {
  name: 'CannaApp_Public',
  mixins: [
    helperBase_Mixin,
    myplatform_Mixin
  ],
  data () {
    return {
      version: process.env.APPVERSION + '',
      useEnv: process.env.ZTEST + '',
      useEnvB: '' + process.env.ZZTEST,
      vimeoTest: {
        //videoSample: '' + process.env.VIMEO_CMS,
        video_CarpetaPROD: '' + process.env.VIMEO_PROD_FOLDERID ,
        video_CarpetaDEV: '' + process.env.VIMEO_DEV_FOLDERID ,
        videoFolder: (process.env.DEV) ? '' + process.env.VIMEO_DEV_FOLDERID :  '' + process.env.VIMEO_PROD_FOLDERID
      },
      useDebug: (parseInt(process.env.USEDEBUG) === 1),
      // ----------
      loading_appData: false,
      // ----------
      cannaReadyApp: false,
      cannaInitReady: false,
      cannaHasSession: false
    }
  },
  props: {
  },
  watch: {
  },
  computed: {
    alertSession () {
      if (this.cannaInitReady===true & this.cannaReadyApp===true) {
        return this.$store.state.myapp.appSessionAlert
      } else {
        return false
      }
    },
    app_xLData () {
      return this.$store.state.myapp.loginData
    },
    routeName () {
      return this.$route.name
    }
  },
  methods: {
    ...requestPack,
    ...canna_cookieMethods,
    test () {
      this.$cannaDebug('-- metodo de prueba ')
    },
    sendAppOut () {
      this.$cannaDebug('-- Salir de APP ')
      // alert('Salir de app')
      window.location = '/'
    }, 
    async doVerificaEmailProcess () {

      var test=11
      // Se revisa la cookie para verificar correo
      var checkVerify = this.$store.state.myapp.appVerifyEmail
      var doExistVerifyCookie = this.$q.cookies.has('CannaV2PublicEmailVx')
      if (checkVerify.action === true && checkVerify.action_done === false && checkVerify.data!==null && doExistVerifyCookie===true){
        var myDVx = checkVerify.data 
        if (myDVx instanceof Object && myDVx.constructor === Object) {

          //Procedemos a REalizar la verificacion del email 
          var mpModel = this.getModelRequest('userVerifica')  // ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=phone
          var mdataModel = this.getModelRequest('userProfile')
          var reqRes = { action: [], profile: [], profileInfo:[] }
          var reqErr = { action: 0, profile: 0, profileInfo:0 }

          await this._waitRequestProc(500)
          
          // -------------------------------------------
          // Se envia la verificacion de email....!

          var myPhoneVerifConfig = this.createAxiosConfig ('get', mpModel, { 
            delimiters:[ checkVerify.data.user_id ], 
            notifier:'email', 
            key: checkVerify.data.key
          }, false, null)

          await this.doRequestByPromise(this.$axios, 'verifEmail', reqRes.action, myPhoneVerifConfig).then((resx) => {
            // console.log ('Action -- verifica telefono Request -- POST ejecutado')
            var tmpresponseData = reqRes.action[reqRes.action.length - 1]
            if (tmpresponseData.type !== 'success') {
              reqErr.action += 1
            }
          })

          if (reqErr.action > 0) { // Si hay error de server, enviamos mensaje personalizado de verificacion fallida 

            var myErrorResp = reqRes.action[0].data.data
            var msgUseError =  '' + myErrorResp.message

            // if (msgUseError.indexOf('la llave ya expiro') ){ 
            //   msgUseError = '¡Vaya! Ya habías validado tu dirección de correo electrónico.<br>Está tarea se encuentra finalizada, gracias por tu esfuerzo'
            // } else { // Casos RAROS del server o mala config del server 
            //   msgUseError = '<b>Error al verificar el correo electrónico:</b><br>' + myErrorResp.message + '<br>Por favor contacte a soporte en general'
            // }

            test = 55
            //Eliminamos la cookie de 'CannaV2PublicEmailVx' para que ya no se vuelva a repetir la verificacion..!
            var testDCA = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2PublicEmailVx' )
            this.$cannaDebug('-- CAP_V2 -- cookie destroy email verif --- ' + testDCA)
            //-------------------------------
            //Actualizamos el Store de Verificacion 
            this.$store.commit('myapp/setAppVerifyEmail', { errors: true, response:'Error : ' + msgUseError }) // Solo 2 props 
            var myMSG = '<b>Error al verificar el correo electrónico:</b><br>' + msgUseError
            this.$q.notify({ type: 'warning', position: 'top', html:true, message: '' + myMSG})

          } else { //Si todo paso bien 

            await this._waitRequestProc(500)
            test = 66
            //-------------------------------
            //Recargamos el perfil de usuario // Si se requiere...!
            if (this.app_xLData.logIn===true && this.app_xLData.user!==null & this.app_xLData.token!==null) {

              var myProfileConfig = this.createAxiosConfig ('get', mdataModel, {}, true, null)
              await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileInfo, myProfileConfig).then((resx) => {
                // console.log ('Action -- Carga de Clientes -- GET ejecutado')
                var tmpresponseData = reqRes.profileInfo[reqRes.profileInfo.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.profileInfo += 1
                }
              })
              if (reqErr.action > 0) { 
                // NOTA : NO deberia haber errores en este paso nunca 
                // Si hay error de server, session o algo redireccionamos..!
                test = 44
                this.ex_handleErrorRequest (reqRes.profileInfo[0], reqErr.profileInfo)
                this.cannaReadyApp = true
                this.cannaInitReady= false
                this.$forceUpdate()
                return false
    }
              // Actualizamos el STORE 
              var profileDataNew = reqRes.profileInfo[0].data.data
              this.$store.commit('myapp/setMainState',{ appUserProfile: profileDataNew })
              await this._waitRequestProc(1000)

            }
            //-------------------------------
            //Eliminamos la cookie de 'CannaV2PublicEmailVx' para que ya no se vuelva a repetir la verificacion..!
            var testDCB = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2PublicEmailVx' )
            this.$cannaDebug('-- CAP_V2 -- cookie destroy email verif --- ' + testDCB)
            await this._waitRequestProc(500)
            //Actualizamos el Store de verificacion
            this.$store.commit('myapp/setAppVerifyEmail', { action_done:true, errors: false, response:'ok' }) // Solo 2 props 
            await this._waitRequestProc(500)
            //-------------------------------
            //Damos la notificacion de Exito.! 
            // if (this.routeName !== 'master_inicio'){ 
            //}
            var myMSGOk = '<b>Verificación de correo electrónico:</b><br>Ha sido ejecutada exitosamente'
            this.$q.notify({ type: 'positive', color:'cannaOKAlert', position: 'top', html:true, message: '' + myMSGOk})

          }
          if (this.$route.name==="mail_verificar"){ 
            //return true
          }
        }
      }
      return true
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- CAP_V2 -- beforeCreate')
  },
  created () {
    this.cannaReadyApp = false
    this.cannaInitReady= false
  },
  beforeMount () { 
    this.$cannaDebug('-- CAP_V2 -- beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- CAP_V2 -- mounted')
    var test=11
    // if (test==22) {

      // Se revisa la cookie para verificar correo
      if (44==333){
      var checkVerify = this.$store.state.myapp.appVerifyEmail
      var doExistVerifyCookie = this.$q.cookies.has('CannaV2PublicEmailVx')

      if (checkVerify.action === true && checkVerify.action_done === false && checkVerify.data!==null && doExistVerifyCookie===true){
        var myDVx = checkVerify.data 
        if (myDVx instanceof Object && myDVx.constructor === Object) {

          //Procedemos a REalizar la verificacion del email 
          var mpModel = this.getModelRequest('userVerifica')  // ${secure}://${server}/index.php/api/rum1/user/104/verify?notifier=phone
          var mdataModel = this.getModelRequest('userProfile')
          var reqRes = { action: [], profile: [], profileInfo:[] }
          var reqErr = { action: 0, profile: 0, profileInfo:0 }

          await this._waitRequestProc(500)
          
          // -------------------------------------------
          // Se envia la verificacion de email....!

          var myPhoneVerifConfig = this.createAxiosConfig ('get', mpModel, { 
            delimiters:[ checkVerify.data.user_id ], 
            notifier:'email', 
            key: checkVerify.data.key
          }, false, null)

          await this.doRequestByPromise(this.$axios, 'verifEmail', reqRes.action, myPhoneVerifConfig).then((resx) => {
            // console.log ('Action -- verifica telefono Request -- POST ejecutado')
            var tmpresponseData = reqRes.action[reqRes.action.length - 1]
            if (tmpresponseData.type !== 'success') {
              reqErr.action += 1
            }
          })

          if (reqErr.action > 0) { // Si hay error de server, enviamos mensaje personalizado de verificacion fallida 

            var myErrorResp = reqRes.action[0].data.data
            var msgUseError =  '' + myErrorResp.message

            // if (msgUseError.indexOf('la llave ya expiro') ){ 
            //   msgUseError = '¡Vaya! Ya habías validado tu dirección de correo electrónico.<br>Está tarea se encuentra finalizada, gracias por tu esfuerzo'
            // } else { // Casos RAROS del server o mala config del server 
            //   msgUseError = '<b>Error al verificar el correo electrónico:</b><br>' + myErrorResp.message + '<br>Por favor contacte a soporte en general'
            // }

            test = 55
            //Eliminamos la cookie de 'CannaV2PublicEmailVx' para que ya no se vuelva a repetir la verificacion..!
            var testDCA = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2PublicEmailVx' )
            this.$cannaDebug('-- CAP_V2 -- cookie destroy email verif --- ' + testDCA)
            //-------------------------------
            //Actualizamos el Store de Verificacion 
            this.$store.commit('myapp/setAppVerifyEmail', { errors: true, response:'Error : ' + msgUseError }) // Solo 2 props 
            var myMSG = '<b>Error al verificar el correo electrónico:</b><br>' + msgUseError
            this.$q.notify({ type: 'warning', position: 'top', html:true, message: '' + myMSG})

          } else { //Si todo paso bien 

            await this._waitRequestProc(500)
            test = 66
            //-------------------------------
            //Recargamos el perfil de usuario // Si se requiere...!
            if (this.app_xLData.logIn===true && this.app_xLData.user!==null & this.app_xLData.token!==null) {

              var myProfileConfig = this.createAxiosConfig ('get', mdataModel, {}, true, null)
              await this.doRequestByPromise(this.$apiV2, 'userlogin', reqRes.profileInfo, myProfileConfig).then((resx) => {
                // console.log ('Action -- Carga de Clientes -- GET ejecutado')
                var tmpresponseData = reqRes.profileInfo[reqRes.profileInfo.length - 1]
                if (tmpresponseData.type !== 'success') {
                  reqErr.profileInfo += 1
                }
              })
              if (reqErr.action > 0) { 
                // NOTA : NO deberia haber errores en este paso nunca 
                // Si hay error de server, session o algo redireccionamos..!
                test = 44
                this.ex_handleErrorRequest (reqRes.profileInfo[0], reqErr.profileInfo)
                this.cannaReadyApp = true
                this.cannaInitReady= false
                this.$forceUpdate()
                return false
              }
              // Actualizamos el STORE 
              var profileDataNew = reqRes.profileInfo[0].data.data
              this.$store.commit('myapp/setMainState',{ appUserProfile: profileDataNew })
              await this._waitRequestProc(1000)

            }
            //-------------------------------
            //Eliminamos la cookie de 'CannaV2PublicEmailVx' para que ya no se vuelva a repetir la verificacion..!
            var testDCB = this.cannack_destroyCookie( this.$q.cookies, 'CannaV2PublicEmailVx' )
            this.$cannaDebug('-- CAP_V2 -- cookie destroy email verif --- ' + testDCB)
            await this._waitRequestProc(500)
            //Actualizamos el Store de verificacion
            this.$store.commit('myapp/setAppVerifyEmail', { action_done:true, errors: false, response:'ok' }) // Solo 2 props 
            await this._waitRequestProc(500)
            //-------------------------------
            //Damos la notificacion de Exito.! 
            // if (this.routeName !== 'master_inicio'){ 
            //}
            var myMSGOk = '<b>Verificación de correo electrónico:</b><br>Ha sido ejecutada exitosamente'
            this.$q.notify({ type: 'positive', color:'cannaOKAlert', position: 'top', html:true, message: '' + myMSGOk})

          }

            if (this.$route.name==="mail_verificar"){ 
              this.sendAppOut()
              return true

        }
      }
        } else {
          if (this.$route.name==="mail_verificar"){ 
            this.sendAppOut()
            return true
          }
        }
      }

    // }
    if (this.$route.name==="mail_verificar"){
      setTimeout( async () => {
        await this.doVerificaEmailProcess().then( resp => { 
          setTimeout(() => {
            this.sendAppOut()
          }, 6500)
          return true
        })
      }, 200)

    } else {

      var respxx  = null 
      await this.doVerificaEmailProcess().then( resp => { 
        respxx = resp
      })

    }

    this.cannaReadyApp = true
    this.cannaInitReady= true
    this.$forceUpdate() 
    return true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- CAP_V2 -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- CAP_V2 -- updated')
  },
  activated () {
    // this.$cannaDebug('-- CAP_V2 -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- CAP_V2 -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- CAP_V2 -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- CAP_V2 -- destroyed')
  }
}
</script>
