import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules.umd'
import { messages } from 'vee-validate/dist/locale/es.json'

// Moment es ppara la validacion de fechas
import moment from 'moment'
const momentz = require('moment-timezone')
momentz.locale('es-mx')

// console.log(rules)
// Object.keys(rules).forEach(rule => {
//   console.log(rule)
// })

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  })
})

const _emojisFreeAlt = function (valorStr) { // FW35 added, funciona mejor q _emojisFree para caso de editors de HTML como "quill editor"
  var emogiReg = '(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])'
  var emojiTester = valorStr.match(emogiReg)
  if (emojiTester === null) {
    return true
  } else {
    return (emojiTester.length > 0) ? false : true
  }
}

const _cannaCURP = function (value) {
  var alphaspacesEs = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
  return (alphaspacesEs).test(value)
}

const _cannaRFC = function (value) {
  var alphaspacesEs = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
  return (alphaspacesEs).test(value)
}

const _cannaNSS = function (value) {
  var alphaspacesEs = /^(\d{2}) ?(?:- ?)?(\d{2}) ?(?:- ?)?(\d{2}) ?(?:- ?)?(\d{4}) ?(?:- ?)?(\d{1})$/
  return (alphaspacesEs).test(value)
}

const _cannaCodigosSMS = function (value) {
  var alphaspacesEs = /^([0-9]*)$/i //A-Za-z
  return (alphaspacesEs).test(value)
}

const _cannaTextoNombresLetras = function (value) {
  var alphaspacesEs = /^([A-Za-z\á\é\í\ó\ú\n\Á\É\Í\Ó\Ú\Ñ\s]*)$/i 
  return (alphaspacesEs).test(value)
}

const _cannaTextoNombres = function (value) {
  var alphaspacesEs = /^([0-9A-Za-z\á\é\í\ó\ú\n\Á\É\Í\Ó\Ú\Ñ\_\-\.\#\&\s\, ]*)$/i 
  return (alphaspacesEs).test(value)
}

const _cannaTextoNombresAlt = function (value) {
  var alphaspacesEs = /^([a-z0-9\á\é\í\ó\ú\ñ\Á\É\Í\Ó\Ú\Ñ\_\-\.\/\s\&\#\,\!\@\¡\?\¿\:\;]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaTextoNombresAltB = function (value) { // Para nombres de personas
  // /^[a-záéíóúñÑ\s]*$/i
  var alphaspacesEs = /^([a-z0-9\á\é\í\ó\ú\ñ\Á\É\Í\Ó\Ú\Ñ]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaTextoAlias = function (value) {
  // var alphaspacesEs = /^[a-zA-Z0-9\s]{2,12}$/i 
  // feb 2002  var alphaspacesEs =  '/^[a-zA-ZÀ-ÿáéíóúñÑ0-9\s]{2,12}$/i' 
  var alphaspacesEs = /^([a-zA-Z0-9À-ÿ\á\é\í\ó\ú\ñ\Á\É\Í\Ó\Ú\Ñ\s]*)$/i
  // var alphaspacesEs = /^([a-zA-Z0-9\s]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaPassword = function (value) {
  var alphaspacesEs = /^([a-z0-9\_\-\.\/\s\&\#\,\!\@\¡\?\¿\(\)\:\;]*)$/i
  // var myregStr =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&#.$($)$-$_])[A-Za-z\d$@$!%*?&#.$($)$-$_]{8,12}$/i
  var myregStr = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*¿+-¡\/=><?&#.$($)$-$_])[A-Za-z\d$@$!%*¿+-¡\/=><?&#.$($)$-$_]{8,12}$/i
  return (myregStr).test(value)
}

const _cannaDIRECCIONtextos = function (value) {
  //'calle' => '/^([a-zA-ZñÑáéíóúÁÉÍÓÚ\,\.\-\s0-9]{5,50})+$/',
  var alphaspacesEs = /^([a-zA-Z0-9\á\é\í\ó\ú\ñ\Á\É\Í\Ó\Ú\Ñ\,\.\-\s]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaPhones = function (value) {
  var alphaspacesEs = /^([MX0-9\(\)\+\-\s]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaPlanIDNombre = function (value) {
  var alphaspacesEs = /^([a-z0-9]*)$/i
  return (alphaspacesEs).test(value)
}

const _cannaSueldos = function (value) {
  if (value === null || value === undefined || value === '') {
    return false
  } else {
    var myValor = '' + value
    var valorMoney = myValor.replace('$ ', '')
    valorMoney = valorMoney.replace(/\,/g, '')
    var valorFloat = parseFloat(valorMoney)
    return (valorFloat <= 0) ? false : true
  }
}

const _cannaSueldosLaboral = function (value) {
  if (value === null || value === undefined || value === '') {
    return false
  } else {
    var myValor = '' + value
    var valorMoney = myValor.replace('$ ', '')
    valorMoney = valorMoney.replace(/\,/g, '')
    var valorFloat = parseFloat(valorMoney)
    if (valorFloat> 99999.99) {
      return false
    } else {
      return (valorFloat <= 0) ? false : true
    }
  }
}

const _cannaHorarios = function (value) {
  // validar q sea un Array y que tenga 1 o 2 elementos  (solamente)
  if (Array.isArray(value)) { 
    return (value.length === 1 || value.length === 2) ? true : false 
  } else {
    return false
  }
}

const _cannaEdad = function (value) {
  if (value === null || value === undefined || value === '') {
    return false
  } else {
    var myValor = '' + value
    // if (myValor === '0 años') {
    //   return true
    // } else {
    // }
    var valorEdad = myValor.replace(' años', '')
    var valorEdadXX = parseInt(valorEdad)
    return (valorEdadXX < 18 || valorEdadXX > 99) ? false : true    
  }
}

const _cannaArray = function (value) {
  // validar q sea un Array y que tenga 1 o 2 elementos  (solamente)
  if (Array.isArray(value)) { 
    return (value.length >= 1) ? true : false 
  } else {
    return false
  }
}

// --------------------
// --------------------
// --------------------
// Add a rule.
extend('canna_dtStagesValid', {
  validate: (value) => {
    if (value === '' || value === undefined || value === null) { 
      return '{_field_} es un valor inválido'
    } else {
      if (value.length === 10) {
        var dtTest = momentz(value, 'YYYY-MMM-DD')
        if (dtTest.isValid()) { 
          return true 
        } else { 
          return '{_field_} no es una fecha valida'
        }
      } else {
        return '{_field_} no es un valor valido'
      }
    }
  }
})

extend ('canna_dtStagesRangeValid', {
  params: ['flag', 'from', 'to'],
  validate: (value, { flag, from, to }) => {
    var testA = (from === '' || from === undefined || from === null) ? false : (from.length === 10) ? true : false 
    var testB = (to === '' || to === undefined || to === null) ? false : (to.length === 10) ? true : false 
    if (testA === false && testB === false) { // Si no esta definido ninguno lo tomamos como true 
      return true
    } else {
      if (flag === 1) { // Validar Fecha inicial
        if (testA === true && testB === false) {
          var dtTestX = momentz(from, 'YYYY-MM-DD')
          if (dtTestX.isValid()) { 
            return true
          } else {
            return '{_field_} no es una fecha valida'
          }
        } else if (testA === true && testB === true) {
          var dtTestXX = momentz(from, 'YYYY-MM-DD')
          var dtTestYY = momentz(to, 'YYYY-MM-DD')
          if (dtTestXX.isValid() && dtTestYY.isValid()) {
            if (dtTestXX.isSameOrBefore(dtTestYY)) {
              return true
            } else {
              return '{_field_} no puede ser mayor a la fecha final'
            }
          } else {
            return '{_field_} esta en una rango de fechas inválido, verifique información'
          }
        }
      } else { // Validar fecha final 
        if (testB === true && testA === false) {
          var dtTestY = momentz(to, 'YYYY-MM-DD')
          if (dtTestY.isValid()) { 
            return true
          } else {
            return '{_field_} no es una fecha valida'
          }
        } else if (testB === true && testA === true) {
          var dtTestXXX = momentz(from, 'YYYY-MM-DD')
          var dtTestYYY = momentz(to, 'YYYY-MM-DD')
          if (dtTestXXX.isValid() && dtTestYYY.isValid()) {
            if (dtTestYYY.isSameOrAfter(dtTestXXX)) {
              return true
            } else {
              return '{_field_} no puede ser menor a la fecha inicial'
            }
          } else {
            return '{_field_} esta en una rango de fechas inválido, verifique información'
          }
        }
      }
    }
  }
})

extend('cannaRenameBaseText', {
  params: ['baseName'],
  validate: (value, { baseName }) => {
    if (value === '' || value === undefined || value === null) {
      return '{_field_} es obligatorio, verifique la información'
    } else { 
      if (value === '' + baseName) {
        return '{_field_} no puede ser el mismo valor, verifique la información'
      } else {
        return true
      }
    }
  }
})

extend('cannaRemoveBaseText', {
  params: ['baseName'],
  validate: (value, { baseName }) => {
    if (value === '' || value === undefined || value === null) {
      return '{_field_} es obligatorio, verifique la información'
    } else { 
      if (value !== 'Eliminar ' + baseName) {
        return '{_field_} no coincide con el texto a escribir, verifique la información'
      } else { 
        return true
      }
    }
  }
})

extend('cannaEdadesCheck', {
  params: ['flag', 'min', 'max'],
  validate: (value, { flag, min, max }) => {
    var valorMin = 0
    var valorMax = 0
    if (flag === 1) { // edad min
      valorMin = (min === '' || min === undefined || min === null) ? 0 : parseInt(min.replace(' años', ''))
      valorMax = (max === '' || max === undefined || max === null) ? 0 : parseInt(max.replace(' años', ''))
      if (valorMin <= valorMax) {
        return true 
      } else { 
        return '{_field_} debe ser menor o igual a edad máxima, verifique la información'
      }
    } else { // edad max
      valorMin = (min === '' || min === undefined || min === null) ? 0 : parseInt(min.replace(' años', ''))
      valorMax = (max === '' || max === undefined || max === null) ? 0 : parseInt(max.replace(' años', ''))
      if (valorMax >= valorMin) {
        return true 
      } else { 
        return '{_field_} debe ser mayor o igual edad mínima, verifique la información'
      }
    }
  }
})

extend('cannaEstaturaCheck', {
  params: ['catalogo', 'flag', 'min', 'max'],
  validate: (value, { catalogo, flag, min, max }) => {
    var indexUseMin = null
    var indexUseMax = null
    var valorMin = 0
    var valorMax = 0
    if (value <= 0 && min === 0 && max === 0) {
      return true 
    } else {
      if (flag === 1) { // estatura min
        if ((min > 0 && max <= 0) || (min === 0 && max !== 0)) {
          return true 
        } else {
          indexUseMin = catalogo.findIndex (item => item.id === min)
          indexUseMax = catalogo.findIndex (item => item.id === max)
          valorMin = parseFloat(catalogo[indexUseMin].nombre)
          valorMax = parseFloat(catalogo[indexUseMax].nombre)
          if (valorMin <= valorMax) {
            return true 
          } else { 
            return '{_field_} debe ser menor o igual a estatura máxima, verifique la información'
          }
        }
      } else { // estatura max
        if ((max > 0 && min <= 0) || (max === 0 && min !== 0)) {
          return true
        } else {
          indexUseMin = catalogo.findIndex (item => item.id === min)
          indexUseMax = catalogo.findIndex (item => item.id === max)
          valorMin = parseFloat(catalogo[indexUseMin].nombre)
          valorMax = parseFloat(catalogo[indexUseMax].nombre)
          if (valorMin <= valorMax) {
            return true
          } else {
            return '{_field_} debe ser mayor o igual a estatura mínima, verifique la información'
          }
        }
      }
    }
  }
})

extend('cannaPesoCheck', {
  params: ['catalogo', 'flag', 'min', 'max'],
  validate: (value, { catalogo, flag, min, max }) => {
    var indexUseMin = null
    var indexUseMax = null
    var valorMin = 0
    var valorMax = 0
    if (value <= 0 && min === 0 && max === 0) {
      return true 
    } else {
      if (flag === 1) { // Peso minimo 
        if ((min > 0 && max <= 0) || (min === 0 && max !== 0)) {
          return true 
        } else {
          indexUseMin = catalogo.findIndex (item => item.id === min)
          indexUseMax = catalogo.findIndex (item => item.id === max)
          valorMin = parseFloat(catalogo[indexUseMin].nombre)
          valorMax = parseFloat(catalogo[indexUseMax].nombre)
          if (valorMin <= valorMax) {
            return true 
          } else { 
            return '{_field_} debe ser menor a peso máximo, verifique la información'
          }
        }
      } else { // Peso maximo 
        if ((max > 0 && min <= 0) || (max === 0 && min !== 0)) {
          return true
        } else {
          indexUseMin = catalogo.findIndex (item => item.id === min)
          indexUseMax = catalogo.findIndex (item => item.id === max)
          valorMin = parseFloat(catalogo[indexUseMin].nombre)
          valorMax = parseFloat(catalogo[indexUseMax].nombre)
          if (valorMin <= valorMax) {
            return true
          } else {
            return '{_field_} debe ser mayor a peso mínimo, verifique la información'
          }
        }
      }
    }
  }
})

extend('cannaArrData', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = _cannaArray(value)
    if (testValue) {
      return true
    }
    return '{_field_} debe tener un valor por lo menos, verifique la información'
  }
})

extend('cannaEdad', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = _cannaEdad(value)
    if (testValue) {
      return true
    }
    return '{_field_} debe tener un valor con rango de 18 años hasta 99 años, verifique la información'
  }
})

extend('cannaHorarioContents', {
  validate: value => {
    var testValue = _cannaHorarios(value)
    if (testValue) {
      return true
    }
    return '{_field_} necesita seleccionar de 1 a 2 opciones, verifique la información'
  }
})

extend('cannaSueldos', {
  validate: value => {
    var testValue = _cannaSueldos(value)
    if (testValue) {
      return true
    }
    return '{_field_} es un valor inválido, verifique la información'
  }
})

extend('cannaSueldosLaboral', {
  validate: value => {
    var testValue = _cannaSueldosLaboral(value)
    if (testValue) {
      return true
    }
    return 'El valor máximo para este campo es $99,999.99 pesos.' //'{_field_} es un valor inválido, valor máximo hasta 99,999.99 verifique la información'
  }
})

extend('emojiFree', {
  validate: value => {
    var testValue = _emojisFreeAlt(value)
    if (testValue) {
      return true
    }
    return '{_field_} no puede contener emojis en el valor, verifique la información'
  }
})

extend('cannaPlanIDName', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPlanIDNombre(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaSMSCode', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaCodigosSMS(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaPhones', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPhones(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaPhonesByRef', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPhones(value)
    if (testValue) {
      return true
    }
    return 'telError1'
  }
})

extend('cannaTextAlias', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoAlias(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaTextPassword', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPassword(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaTextPasswordAlt', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPassword(value)
    if (testValue) {
      return true
    }
    return 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.'
  }
})

extend('cannaTextPasswordConfAlt', {
  params: ['target'],
  validate: (value, {target }) => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaPassword(value)
    if (testValue) {
      if (target != undefined ){ 
        var testTarget = (value === target) ? true :  'La confirmación no coincide con la nueva contraseña' // 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.'
        return testTarget
      }
      return true
    }
    return 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.'
  }
})

extend('cannaTextNamesLetras', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoNombresLetras(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaTextNames', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoNombres(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaTextNamesAlt', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoNombresAlt(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaTextIdOficial_solicita', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoNombresAltB(value)
    if (testValue) {
      return true
    }
    return '{_field_} contiene caracteres inválidos verifique la información'
  }
})


extend('cannaTextOtros', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaTextoNombresAlt(value)
    if (testValue) {
      return true
    }
    return '{_field_} solo puede contener dígitos, á é í ó ú ñ Ñ _ - , . # ¡ ! @ ¿ ? : ; y espacios en blanco. Verifique la información'
  }
})

extend('cannaCURP', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaCURP(value)
    if (testValue) {
      return true
    }
    return '{_field_} es un valor inválido, verifique la información'
  }
})

extend('cannaRFC', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaRFC(value)
    if (testValue) {
      return true
    }
    return '{_field_} es un valor inválido, verifique la información'
  }
})

extend('cannaNSS', {
  validate: value => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaNSS(value)
    if (testValue) {
      return true
    }
    return '{_field_} es un valor inválido, verifique la información'
  }
})

// --------------------
// --------------------
// Reglas especificas para cuestionarios 
extend('cannaQuestPuestosMin', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length>=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Debes seleccionar mínimo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en está lista.'
  }
})

extend('cannaQuestPuestosMax', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length<=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Recuerda que sólo puedes seleccionar máximo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en está lista.'
  }
})

extend('cannaQuestHorariosMin', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length>=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Debes seleccionar mínimo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en la sección Horario.'
  }
})

extend('cannaQuestHorariosMax', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length<=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Recuerda que sólo puedes seleccionar máximo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en la sección Horario.'
  }
})

extend('cannaQuestCanalesMin', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length>=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Debes seleccionar mínimo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en esta sección.'
  }
})


extend('cannaQuestSocialesMin', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length>=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Debes seleccionar mínimo ' + args[0] + ' opción en la sección ¿Cómo te enteraste de nosotros?.'
    //return '¡Espera! Debes seleccionar mínimo ' + args[0] + ' opci' + ((args[0]>1)?'ones':'ón') + ' en la sección ¿Cómo te enteraste de nosotros?.'
  }
})

extend('cannaQuestSocialesMax', {
  validate: (value, args) => {
    var myArgs = args
    var testValue = (value.length<=args[0])
    if (testValue) {
      return true
    }
    return '¡Espera! Recuerda que sólo puedes seleccionar máximo ' + args[0] + ' opciones en la sección ¿Cómo te enteraste de nosotros?.'
    // + ((args[0]>1)?'ones':'ón') + ' en la sección ¿Cómo te enteraste de nosotros?.'
  }
})

extend('cannaQuest_DateIsValid', {
  validate: (value) => {
    if (value === '' || value === undefined || value === null) { 
      return '{_field_} es un valor inválido'
    } else {
      if (value.length === 10) {
        var dtTest = momentz(value, 'YYYY-MMM-DD')
        if (dtTest.isValid()) { 
          return true 
        } else { 
          return '{_field_} no es una fecha valida'
        }
      } else {
        return '{_field_} no es un valor valido'
      }
    }
  }
})

extend('cannaTextoParaDireccion', {
  params: ['min','max'],
  validate: (value, { min, max} ) => {
    if (value === null || value === undefined || value === '') {
      return '{_field_} es un dato obligatório'
    }
    var testValue = _cannaDIRECCIONtextos(value)
    if (testValue && value.length>=min && value.length<=max) {
      return true
    }
    var ErrorStr = 'Solo puedes ingresar entre ' + min + ' y ' + max + ' caracteres pueden ser letras, números, y los signos: . , -'
    return ErrorStr
    //return '{_field_} contiene caracteres inválidos verifique la información'
  }
})

extend('cannaQuest_escuelaFechaFinVsInicio', {
  params: ['tipoFinUse','fechaIni','fechaFin'],
  validate: (value, { tipoFinUse, fechaIni} ) => {
    var fechaFin = value 
    if (tipoFinUse === 753) {
      if (fechaIni === '' || fechaIni === undefined || fechaIni === null) { 
        return '{_field_} Es necesario establecer fecha de inicio y fecha de fin. Por favor, revisa e inténtalo de nuevo.'
      } else {
        if (fechaIni.length === 10) {
          var dtTest = momentz(fechaIni, 'YYYY-MM-DD')
          if (dtTest.isValid()) { 

            if (fechaFin != undefined ){ 
              if (fechaFin.length === 10) {
                var dtFinalTest = momentz(fechaFin, 'YYYY-MM-DD')
                if (dtFinalTest.isValid()) { 
                    var FechaIniStr = dtTest.format('YYYY-MM') 
                    var FechaFinStr = dtFinalTest.format('YYYY-MM')
                    if (dtFinalTest.isSameOrBefore(dtTest)===true || FechaFinStr === FechaIniStr) {
                      return '{_field_} El año y mes de la fecha de fin de tus estudios no puede ser igual ó menor al de la fecha de inicio. Por favor, revisa e inténtalo de nuevo.'
                    } else {
                      return true 
                    }
                } else { //Si la fecha inicial todvia no esta completa 
                  return true 
                }
              } else { //Si la fecha inicial todvia no esta completa 
                return true 
              }
            } else { //Si la fecha inicial todvia no esta completa 
              return true 
            }
          } else {
            return '{_field_} Es necesario establecer fecha de inicio y fecha de fin correctamente. Por favor, revisa e inténtalo de nuevo.'
          }
        } else {
          return '{_field_} Es necesario establecer fecha de inicio y fecha de fin correctamente. Por favor, revisa e inténtalo de nuevo.'
        }
      }
    } else {
      return true
    }
  }
})

extend('cannaQuest_laboralFechaFinVsInicio', {
  params: ['tipoFinUse','fechaIni','fechaFin'],
  validate: (value, { tipoFinUse, fechaIni} ) => {
    var fechaFin = value 
    if (tipoFinUse === 0) {
      if (fechaIni === '' || fechaIni === undefined || fechaIni === null) { 
        return '{_field_} Es necesario establecer fecha de inicio y fecha de fin. Por favor, revisa e inténtalo de nuevo.'
      } else {
        if (fechaIni.length === 10) {
          var dtTest = momentz(fechaIni, 'YYYY-MM-DD')
          if (dtTest.isValid()) { 

            if (fechaFin != undefined ){ 
              if (fechaFin.length === 10) {
                var dtFinalTest = momentz(fechaFin, 'YYYY-MM-DD')
                if (dtFinalTest.isValid()) { 
                    var FechaIniStr = dtTest.format('YYYY-MM') 
                    var FechaFinStr = dtFinalTest.format('YYYY-MM')
                    if (dtFinalTest.isSameOrBefore(dtTest)===true || FechaFinStr === FechaIniStr) {
                      return '{_field_} El año y mes de la fecha de fin de tu empleo no puede ser igual ó menor al de la fecha de inicio. Por favor, revisa e inténtalo de nuevo.'
                    } else {
                      return true 
                    }
                } else { //Si la fecha inicial todvia no esta completa 
                  return true 
                }
              } else { //Si la fecha inicial todvia no esta completa 
                return true 
              }
            } else { //Si la fecha inicial todvia no esta completa 
              return true 
            }
          } else {
            return '{_field_} Es necesario establecer fecha de inicio y fecha de fin correctamente. Por favor, revisa e inténtalo de nuevo.'
          }
        } else {
          return '{_field_} Es necesario establecer fecha de inicio y fecha de fin correctamente. Por favor, revisa e inténtalo de nuevo.'
        }
      }
    } else {
      return true
    }
  }
})

extend('cannaQuest_NotSameAsMainPhone', {
  params: ['mainPhone'],
  validate: (value, { mainPhone } ) => {
    var myPhone = '+52' + value 
    if (myPhone.length===13){
      if (myPhone === mainPhone){ 
        return '¡Espera! El {_field_} no puede ser el mismo que tu número celular.'
      } else {
        return true 
      }
    } else {
      return true 
    }
  }
})

extend('cannaQuest_NotSameAsRecadosPhone', {
  params: ['mainPhone'],
  validate: (value, { mainPhone } ) => {
    var myPhone = '+52' + value 
    var myRecadosPhone = '+52' +  mainPhone
    if (myPhone.length===13 && myRecadosPhone.length===13){
      if (myPhone === myRecadosPhone) {
        return '¡Espera! El {_field_} no puede ser el mismo que el número de recados.'
      } else {
        return true 
      }
    } else {
      return true 
    }
  }
})

// extend('cannaTextPasswordConfAlt', {
//   params: ['target'],
//   validate: (value, {target }) => {
//     if (value === null || value === undefined || value === '') {
//       return '{_field_} es un dato obligatório'
//     }
//     var testValue = _cannaPassword(value)
//     if (testValue) {
//       if (target != undefined ){ 
//         var testTarget = (value === target) ? true :  'La confirmación no coincide con la nueva contraseña' // 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.'
//         return testTarget
//       }
//       return true
//     }
//     return 'La contraseña no cumple con los requisitos mínimos. Por favor, revisa las indicaciones e inténtalo de nuevo.'
//   }
// })

// extend('secret', {
//   validate: value => value === 'mysecret',
//   message: 'No es la palabra secreta'
// })

// Register it globally
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

