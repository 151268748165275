import Vue from 'vue'
import axios from 'axios'
import { canna_cookieMethods } from './__cannav2_app'

export default ({ store }) => {

  const options = {
    baseURL: process.env.APIV2,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  }

  var cannaAxios = axios.create(options)

  const clientData = canna_cookieMethods.cannack_getCookieData()

  if (clientData && clientData.ipClient) {
    cannaAxios.defaults.headers.common['x-forwarded-for'] = clientData.ipClient
  }

  if (clientData && clientData.token) {
    cannaAxios.defaults.headers.common.Authorization = `Bearer ${clientData.token}`
  } else {
    cannaAxios.defaults.headers.common.Authorization = 'Basic dGVzdGNsaWVudDp0ZXN0cGFzcw=='
  }

  const cannaApiService = {
    get (url, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.get(url, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    delete (url, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.delete(url, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    head (url, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.head(url, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    options (url, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.options(url, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    post (url, data, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.post(url, data, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    put (url, data, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.put(url, data, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    patch (url, data, config) {
      return new Promise((resolve, reject) => {
        cannaAxios.patch(url, data, config)
          .then(response => (resolve(response)))
          .catch(error => {
            try {
              if (error.response.status === 401) {
                store.commit('myapp/appLogout', { exit: true })
              }
            } catch {}
            reject(error)
          })
      })
    },
    $cannaAxios: cannaAxios
  }

  Vue.prototype.$cannaApiService = cannaApiService

  Vue.prototype.$objToForm = obj => {
    let formData = new FormData()
    for (let fieldName in obj) {
      formData.append(fieldName, obj[fieldName])
    }
    return formData
  }
}