/* utileria general para los consoles (en debug mode - dev environment) */
import Vue from 'vue'

const cannadebug = function (msg, optx) {
  var useDebug = (parseInt(process.env.USEDEBUG) === 1)
  if (useDebug) {
    if (optx !== undefined) {
      console.log(msg)
    } else {
      console.log('' + msg)
    }
  }
}

Vue.prototype.$cannaDebug = cannadebug
