

const routes = [
  {
    path: '/',
    component: () => import('layouts/app_layout.vue'),
    children: [
      {
        path: '', 
        name: 'master_inicio',
        component: () => import('pages/login/index.vue'),
        meta: { pageName:'Canna - inicio'}
      },
      {
        path: 'recuperar-acceso', 
        name: 'recuperaracceso_page',
        component: () => import('pages/login/recoverPassw.vue'),
        meta: { pageName:'Canna - recuperar acceso'}
      },
      {
        path: 'restaurar-acceso',  // cambiodeacceso
        name: 'cambiodeacceso_page',
        component: () => import('pages/login/resetPassw.vue'),
        meta: { pageName:'Canna - Cambio de acceso'}
      },
      { path: 'nuevacuenta',
        component: () => import('pages/account/index_cuenta.vue') ,
        children: [
          {
            path:'',
            name:'cuenta_index',
            component: () => import('pages/account/index.vue') ,
            meta: { pageName:'Nueva cuenta'}
          }
        ]
      }
    ]
  },
  {
    path: '/dashboard',
    component: () => import('layouts/dash_layout.vue'),
    children: [
      { 
        path: '',
        component: () => import('pages/dashboard/index.vue') ,
        children: [
          {
            path:'',
            name:'dashboard_main_page',
            component: () => import('pages/dashboard/pages/index.vue') ,
            meta: { pageName:'Canna - Inicio de usuario'}
          },
          {
            path:'docs',
            name:'dashboard_documents_page',
            component: () => import('pages/dashboard/documents/index.vue') ,
            meta: { pageName:'Documentos'}
          },
          {
            path:'videos',
            name:'dashboard_videos_page',
            component: () => import('pages/dashboard/videos/index.vue') ,
            meta: { pageName:'Videos'}
          },
          {
            path:'vacantes',
            component: () => import('pages/dashboard/vacantes/index_main.vue') ,
            children: [
              {
                path:'',
                name:'dashboard_vacantes_page',
                component: () => import('pages/dashboard/vacantes/vacantes_index.vue') ,
                meta: { pageName:'Vacantes'}
              },
              {
                path:':idx(\\d+)',
                name:'dashboard_vacantesdetail_page',
                component: () => import('pages/dashboard/vacantes/vacantes_detail.vue') ,
                meta: { pageName:'Vacantes - detalle'}
              }
            ]
          },
          {
            path:'personal',
            component: () => import('pages/dashboard/personal/index.vue') ,
            children: [
              {
                path:'',
                name:'dashboard_pi_foto',
                component: () => import('pages/dashboard/personal/qp/pi_a_foto.vue') ,
                meta: { pageName:'Información personal - Foto'}
              },
              {
                path:'trabajo-b',
                name:'dashboard_pi_puestos',
                component: () => import('pages/dashboard/personal/qp/pi_b_puestos.vue'),
                meta: { pageName:'Información personal - Puestos'}
              },
              {
                path:'trabajo-c',
                name:'dashboard_pi_sueldos',
                component: () => import('pages/dashboard/personal/qp/pi_c_sueldo.vue'),
                meta: { pageName:'Información personal - Sueldos'}
              },
              {
                path:'trabajo-d',
                name:'dashboard_pi_horario',
                component: () => import('pages/dashboard/personal/qp/pi_d_horariojornada.vue'),
                meta: { pageName:'Información personal - Horario'}
              },
              {
                path:'direccion-a',
                name:'dashboard_pi_direccion',
                component: () => import('pages/dashboard/personal/qp/pi_e_direccion.vue'),
                meta: { pageName:'Información personal - Dirección'}
              },
              {
                path:'direccion-b',
                name:'dashboard_pi_pais',
                component: () => import('pages/dashboard/personal/qp/pi_f_pais.vue'),
                meta: { pageName:'Información personal - País'}
              },
              {
                path:'direccion-c',
                name:'dashboard_pi_licencia',
                component: () => import('pages/dashboard/personal/qp/pi_g_licencia.vue'),
                meta: { pageName:'Información personal - Licencia'}
              },
              {
                path:'direccion-d',
                name:'dashboard_pi_tieneauto',
                component: () => import('pages/dashboard/personal/qp/pi_h_tieneauto.vue'),
                meta: { pageName:'Información personal - Tiene automóvil'}
              },
              {
                path:'uniformes-a',
                name:'dashboard_pi_medidas',
                component: () => import('pages/dashboard/personal/qp/pi_i_medidas.vue'),
                meta: { pageName:'Información personal - Medidas'}
              },
              {
                path:'aptitudes-a',
                name:'dashboard_pi_escuela',
                component: () => import('pages/dashboard/personal/qp/pi_j_escolaridad.vue'),
                meta: { pageName:'Información personal - Escolaridad'}
              },
              {
                path:'aptitudes-b',
                name:'dashboard_pi_canales',
                component: () => import('pages/dashboard/personal/qp/pi_k_canales.vue'),
                meta: { pageName:'Información personal - Canales de experiencia'}
              },
              {
                path:'aptitudes-c', //dev-sprint12 nueva pantalla 
                name:'dashboard_pi_experiencialaboral',
                component: () => import('pages/dashboard/personal/qp/pi_kb_laboral.vue'),
                meta: { pageName:'Información personal - Últimos empleos'}
              },
              {
                path:'aptitudes-d', //old path 'aptitudes-c', 
                name:'dashboard_pi_curriculum',
                component: () => import('pages/dashboard/personal/qp/pi_l_curriculum.vue'),
                meta: { pageName:'Información personal - Currículum vitae'}
              },
              {
                path:'mediossociales',
                name:'dashboard_pi_sociales',
                component: () => import('pages/dashboard/personal/qp/pi_m_sociales.vue'),
                meta: { pageName:'Información personal - Sociales'}
              },
              {
                path:'revision',
                name:'dashboard_pi_review',
                component: () => import('pages/dashboard/personal/qp/pi_n_review.vue'),
                meta: { pageName:'Información personal - Sociales'}
              }
            ]
          }
          // ,
          // {
          //   path: 'solicitudes',
          //   name: 'dashboard_solicitudes_page',
          //   component: () => import('pages/dashboard/solicitudes/index_solicitud.vue') ,
          //   meta: { pageName:'Solicitudes'}
          // }
        ]
      }
    ]
  },
  {
    path: '/settings',
    component: () => import('layouts/settings_layout.vue'),
    children: [
      { path: '',
        component: () => import('pages/settings/index.vue') ,
        children: [
          {
            path:'',
            name:'settings_main_account',
            component: () => import('pages/settings/account_page.vue') ,
            meta: { pageName:'Configuracion - cuenta'}
          },
          {
            path:'password',
            name:'settings_main_password',
            component: () => import('pages/settings/pass_page.vue') ,
            meta: { pageName:'Configuracion - contraseña'}
          }
        ]
      }
    ]
  },
  // verificar email 
  {
    path: "/verificar",
    name: "mail_verificar",
    component: () => import("pages/Email_verifica.vue"),
    meta: { pageName: "Verificacion de email" },
  },
  // PDF visor 
  {
    path: "/pdf-viewer",
    name: "pdf-viewer",
    component: () => import("pages/pdf-visor-page.vue"),
    meta: { pageName: "Visor de documentos" },
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    name: "canna_notfoundpage",
    component: () => import('pages/Error404.vue'),
    meta: { rol: null,  pageName: "No existe contenido" }, 
    alias: '/404'
  }
]

export default routes
