import Vue from 'vue'
import axios from 'axios'
const queryString = require('query-string')

// Demo
const appAxiosApiV1 = axios.create({
  baseURL: '' + process.env.APIV1
})
const appAxiosApiV2 = axios.create({
  baseURL: '' + process.env.APIV2
})

const myAxios = axios.create()

Vue.prototype.$axios = axios
Vue.prototype.$axiosX = myAxios

Vue.prototype.$apiV2 = appAxiosApiV2 // uso de api v2

// Rutinas para Otra forma de Hacer requests en secuencias multiples o Asyncronas 
const _waitRequestProc = async function (ms) { // Timer para Esperar de manera asyncrona entre requests 
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

const doRequestByPromise = function (AxiosInstance, type, resRequest, objectTodo) { // DebugRef-PDVsharedcode --doxRequestxByxPromise_-279d8as3d18as7d6sa5d4asd654asd456
  // console.log('-- PDV shared code -- do Request By Promise -- ');
  var self = this
  return new Promise((resolve, reject) => {
    AxiosInstance(objectTodo).then((response) => {
      resRequest.push({ type: 'success', data: response })
      return resolve(response)
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          resRequest.push({ type: 'errorSession', data: error.response })
          try {
            if (self.$store.state.myapp.appUserData.access_token !== ''){
              self.$store.commit('myapp/appLogout', { exit: true })
            }
          } catch (ex) {
            // do nothing
          }
        } else {
          resRequest.push({ type: 'error', data: error.response })
        }
      } else {
        resRequest.push({ type: 'error', data: undefined })
      }
      return resolve(error)
    })
  })
}

const createAxiosConfig = function (method, model, params, useInstance, AxiosParams ) {
  // AxiosParams solo se manda al inicio del app (no se usa nunca mas)
  // Crear configuracion (objeto) para hacer llamadas con axios
  // console.log('-- createAxiosConfig -- rutina --')
  var myConfigRes = {}
  var testerD = null
  var myToken = '' 
  var baseUrl = ''
  var hasPArams = params.hasOwnProperty('delimiters') ? true : false 
  var myAppData = null // this.getAppData
  var apiversion = model[0]

  if (AxiosParams !== undefined && AxiosParams !== null){

    myAppData = AxiosParams.myAppData
    baseUrl = '' + AxiosParams.baseUrl
    myToken = '' + AxiosParams.access_token

  } else {

    myAppData = this.getAppData
    myToken = (myAppData.hasOwnProperty('access_token')) ? myAppData.access_token : ''
    if (useInstance === undefined || useInstance === null || useInstance === false) {
      baseUrl = (apiversion === 'regx') ? process.env.APIV2REG : 
        (apiversion === 'v2' || apiversion === 'register' || apiversion === 'rcpass') ? process.env.APIV2 : 
          (apiversion === 'loginx') ? process.env.APIV2ACCESS : ''
    }
    baseUrl += '' // '/'

  }
  // Model[1] solo puede ser string o Array 
  if (Array.isArray(model[1])) { // armamos la url con delimiters
    baseUrl+= '/'
    var urlComplement = ''
    var namesx = model[1] 
    var indexx = 0
    testerD = params.hasOwnProperty('delimiters')
    if (testerD) {

      switch (typeof params.delimiters) {
        case 'object': 
          for (indexx = 0; indexx < namesx.length; indexx++) {
            urlComplement += namesx[indexx]
            urlComplement += (params.delimiters[indexx]) ? '/' + params.delimiters[indexx] : ''
            urlComplement += (parseInt(indexx) < namesx.length - 1) ? '/' : ''
          }
          break
        case 'string':
        case 'number':
          for (indexx = 0; indexx < namesx.length; indexx++) {
            urlComplement += namesx[indexx]
            urlComplement += (indexx === 0) ? '/' + params.delimiters : ''
            urlComplement += (parseInt(indexx) < namesx.length - 1) ? '/' : ''
          }
          break
        case 'undefined':
          urlComplement += namesx[0]
          break
        default:
          urlComplement += ''
          break
      }

    } else {
      for (indexx = 0; indexx < namesx.length; indexx++) {
        urlComplement += namesx[indexx]
        urlComplement += (parseInt(indexx) < namesx.length - 1) ? '/' : ''
      }
    }
    baseUrl += '' + urlComplement

  } else { // string

    baseUrl += '' + ((model[1]==='') ? '' : '/') + model[1]
    testerD = params.hasOwnProperty('delimiters')
    if (testerD) {
      baseUrl += (params.delimiters[0]) ? '/' + params.delimiters[0] : ''
    }

  }

  // Anexamos el token 
  baseUrl += ['loginx', 'regx', 'register', 'rcpass' ].indexOf (apiversion) >= 0 ? '' : '?accessToken=' + myToken
	//(apiversion === 'loginx' || apiversion === 'regx' || apiversion === 'register') ? '' : '?accessToken=' + myToken

  // armamos los datos dependiendo del metodo
  if (hasPArams) {
    delete params.delimiters
  }

  if (method.toLowerCase() === 'get') {

    myConfigRes = {
      method: 'get',
      url: baseUrl,
      params: params,
      headers: {
        'x-forwarded-for': '' + myAppData.ipClient
      }
    }

  } else if (method.toLowerCase() === 'post' || method.toLowerCase() === 'patch') { // WIP  mas cosas para agregar 

    var useUrl = baseUrl
    var testerUrlExtraParams = params.hasOwnProperty('urlmixData')
    if (testerUrlExtraParams === true) {
      var extraDataUrl = queryString.stringify(params.urlmixData)
      useUrl += (apiversion === 'loginx' || apiversion === 'regx'  || apiversion === 'register') ? '?' : '&'
      useUrl += extraDataUrl
    }

    var hasFileForm = params.hasOwnProperty('isFileUpload') ? true : false  //Para controlar el fileUpload 
    if (hasFileForm === true){
      delete params.isFileUpload
    }

    myConfigRes = {
      method: '' + method.toLowerCase(),
      url: useUrl,
      headers: {
        'x-forwarded-for': '' + myAppData.ipClient,
        'content-type': (hasFileForm === false) ? 'application/x-www-form-urlencoded' : 'multipart/form-data' 
      }
    }
    
    if (apiversion === 'loginx'){ 
      myConfigRes.headers.Authorization = 'Basic dGVzdGNsaWVudDp0ZXN0cGFzcw=='
    }

    // multipart/form-data  cuando sea archivos
    if (method.toLowerCase() === 'post' && params.hasOwnProperty('useData') === false) {
      if (params.hasOwnProperty('data')){
        myConfigRes.params = params.data
      }
    } else if (method.toLowerCase() === 'patch' && params.hasOwnProperty('useData') === false) {
      if (params.hasOwnProperty('data')){
        myConfigRes.data = queryString.stringify(params.data)
      }
    } else {
      if (hasFileForm === true){
        myConfigRes.data = params.formData
        //   axios.post(
        //     me.config.baseURL + me.config.dataURL + lastURL, 
        //     getData.formData,
        //     {
        //       headers: {
        //         'Content-Type': 'multipart/form-data'
        //       }
        //     }
        // ).then(getSuccess).catch(getError);
      } else { 
        if (params.hasOwnProperty('data')){
          myConfigRes.data = queryString.stringify(params.data)
        }
      }
    }

  } else if (method.toLowerCase() === 'remove' || method.toLowerCase() === 'delete') { // WIP  mas cosas para agregar 

    myConfigRes = {
      method: 'delete',
      url: baseUrl,
      headers: {
        'x-forwarded-for': '' + myAppData.ipClient
      }
    }

  }

  return myConfigRes
}

const requestPack = {
  createAxiosConfig, 
  _waitRequestProc,
  doRequestByPromise
}

export {
  axios,
  requestPack,
  appAxiosApiV1
}
